import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getResponse, getResponsePRO, widgetDB } from "./firebase";
import { WidgetDoc } from "./types";
import { ChatDoc, LeadData, LocationResponse } from "../types";
import { setSessionStorage } from "../hooks/use-session-storage";

//-------------------------------------------------------------------

export const DEFAULT_LOGO =
  "https://firebasestorage.googleapis.com/v0/b/chat-bot-ai-147ba.appspot.com/o/default.png?alt=media&token=9a9263b4-5d30-415e-941a-8ebc2f1ff0aa";

//-------------------------------------------------------------------

export const getWidgetById = async (widgetId: string): Promise<WidgetDoc> => {
  const docRef = doc(widgetDB, `widgets/${widgetId}`);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as WidgetDoc;
};

export const createChatDoc = async (
  widgetId: string,
  ownerUid: string,
  leadData: LeadData | null,
  assistantId: string,
  isHidden?: boolean,
): Promise<string> => {
  const newDocId = doc(collection(widgetDB, `widgets/${widgetId}/chats`)).id;
  let userLocation: LocationResponse = {
    ipVersion: 'unknown',
  } as any;
  try {
    const userLocationReq = await fetch('https://freeipapi.com/api/json');
    userLocation = await userLocationReq.json();
  } catch (error) {
    console.error('Error fetching user location:', error);
  }


  const newLeadRef = doc(collection(widgetDB, `users/${ownerUid}/leads`));

  const newMessage = {
    type: 'text',
    role: 'user' as any,
    content: `leadId value: ${newLeadRef.id};
    userId value: ${ownerUid};
    `,
    createdAt: Timestamp.now(),
  } as any;


  const consentStatus = localStorage.getItem("terms_status") as "accepted" | "ignored";

  const newChat: ChatDoc = {
    chatId: newDocId,
    createdAt: serverTimestamp(),
    messages: [newMessage],
    createdBy: "user",
    widgetId,
    ownerUid,
    integrationOwnerUid: ownerUid,
    leadEmail: leadData?.email || null,
    leadId: newLeadRef.id,
    isAcceptTerms: consentStatus === "accepted",
    isIgnoreTerms: consentStatus === "ignored",
    lead: leadData
      ? {
          isHidden,
          location: userLocation,
          info: leadData,
        }
      : ({
          isHidden,
          location: userLocation,
        } as any),
  };

  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, newDocId);

  const getLeadByEmail = async () => {
    try {
      await setDoc(newLeadRef, {
        widgetId,
        ownerUid,
        info: {
          ...leadData,
        },
        isHidden,
        location: userLocation,
        leadId: newLeadRef.id,
        createdAt: serverTimestamp(),
      });
      // const leadsCollection = collection(widgetDB, `users/${ownerUid}/leads`);
      // const leadsQuery = query(
      //   leadsCollection,
      //   where('email', '==', leadData?.email || '12'),
      // );
      // const querySnapshot = await getDocs(leadsQuery);

      // if (!querySnapshot.empty) {
      //   return true;
      // } else {
      //   await setDoc(newLeadRef, {
      //     widgetId,
      //     ownerUid,
      //     info: {
      //       ...leadData,
      //     },
      //     isHidden,
      //     location: userLocation,
      //     leadId: newLeadRef.id,
      //   });

      //   const userRef = doc(widgetDB, 'users', ownerUid);
      //   const userSnap = await getDoc(userRef);

      //   return false;
      // }
    } catch (error) {
      console.error("Error fetching lead:", error);
      return false;
    }
  };

  await setDoc(docRef, newChat, { merge: true });
  await getLeadByEmail();


  await getResponsePRO({
    assistantId,
    chatId: newDocId,
    widgetId,
    onlyAddToThread: true,
  });

  return newDocId;
};

export const getMessage = async (
  widgetId: string,
  chatId: string,
  assistantId: string,
  message: string,
  messages: any[],
  isPaid?: boolean
) => {
  try {
    const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

    const newMessage = {
      type: "text",
      role: "user",
      content: message,
      createdAt: Timestamp.now(),
    };

    await setDoc(
      docRef,
      {
        messages: arrayUnion(newMessage),
      },
      { merge: true }
    );

    if (true) {
      // isPaid
      await getResponsePRO({
        assistantId,
        chatId,
        widgetId,
      });
    } else {
      await getResponse({
        assistantId,
        chatId,
        widgetId,
      });
    }
  } catch (error: any) {
    if (error?.code === "functions/deadline-exceeded") {
      if (true) {
        // isPaid
        throw new Error("Sorry, your request timed out.");
      } else {
        throw new Error("Please upgrade to PRO to increase timeout.");
      }
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("timeout")) {
      throw new Error("Timeout. Please try again.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("internal")) {
      throw new Error("Something went wrong. Please try again.");
    }
    throw error;
  }
};

export const clearAllConversation = async (
  widgetId: string,
  chatId: string
) => {
  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

  setSessionStorage(`user-widget-id-${widgetId}`, "");
};
