"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { BotStatus, ChatDoc, LeadData, Message } from "./types";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useWidgetSettingsContext } from "./settings";
import { clearAllConversation, createChatDoc, getMessage } from "./lib/firestore";
import { useMediaQuery, useTheme } from "@mui/material";
import { mockResponse } from "./mockData";
import { getStorage, setStorage } from "./hooks/use-local-storage";
import { widgetDB } from "./lib/firebase";

interface ChatContextType {
  messages: ChatDoc['messages'];
  errorMessage: string;
  sendMessage: (userMessage: string, messages: ChatDoc['messages']) => void;
  clearConversation: () => void;
  status: BotStatus;
  setStatus: React.Dispatch<React.SetStateAction<BotStatus>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  isHome?: boolean;
  chatId?: string | null | undefined;
  applyLeadInfo: (leadData: LeadData) => Promise<void>;
  leadInfo?: LeadData;
  isAcceptedTerms?: boolean;
  isIgnoreTerms?: boolean;
  voiceIsReady?: boolean;
  setVoiceIsReady?: React.Dispatch<React.SetStateAction<boolean>>;
  messageWasSent?: boolean;
  waitingForNewResponse?: boolean;
  setWaitingForNewResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatContext = React.createContext<ChatContextType>({
  clearConversation: () => {},
  messages: [],
  sendMessage: () => {},
  status: 'online',
  setStatus: () => {},
  chatOpen: false,
  setChatOpen: () => {},
  errorMessage: '',
  chatId: undefined,
  applyLeadInfo: async () => {},
  leadInfo: undefined,
  isAcceptedTerms: false,
  isIgnoreTerms: false,
  voiceIsReady: true,
  messageWasSent: false,
  setWaitingForNewResponse: () => {},
});

interface ChatContextProviderProps {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string;
  defaultOpen?: boolean;
  isHome?: boolean;
}

// ----------------------------------------------------------------------

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  editMode,
  widgetId,
  defaultOpen,
  isHome,
}) => {
  const [messages, setMessages] = useState<ChatDoc['messages']>([]);
  const [status, setStatus] = useState<BotStatus>('online');
  const theme = useTheme();
  const [leadInfo, setLeadInfo] = useState<LeadData>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [chatOpen, setChatOpen] = useState<boolean>(
    (!!editMode || !!defaultOpen) && isSmUp,
  );
  const DB = widgetDB;

  console.log('context initialized');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [voiceIsReady, setVoiceIsReady] = useState<boolean>(true);

  const widgetSettings = useWidgetSettingsContext();

  const isPaid = !!widgetSettings?.plan;

  const [waitingForNewResponse, setWaitingForNewResponse] = useState(false);

  const [messageWasSent, setMessageWasSent] = useState<boolean>(false);

  const chatRef = doc(
    DB,
    'widgets',
    widgetId || '0',
    'chats',
    getStorage(`user-widget-id-${widgetId}`) || '0',
  );

  const [chatId, setChatId] = useState<string | undefined | null>(
    getStorage(`user-widget-id-${widgetId}`),
  );

  const numberOfMessagesForStaticForm = widgetSettings.staticForm || 1;

  console.log('numberOfMessagesForStaticForm', numberOfMessagesForStaticForm);

  const { data, status: dataStatus } = useFirestoreDocData(chatRef);

  const isAcceptedTerms = (data as ChatDoc)?.isAcceptTerms;
  const isIgnoreTerms = (data as ChatDoc)?.isIgnoreTerms;

  useEffect(() => {
    if (data) {
      const chat = data as ChatDoc;
      const lead = chat?.lead?.info;

      const username = lead?.username || '';
      const email = lead?.email || '';
      const phone = lead?.phone || '';

      if (username) {
        setLeadInfo({
          username,
          email,
          phone,
        });
      }
    }
  }, [data]);

  const db = widgetDB;

  const userRef = doc(db, 'users', widgetSettings.ownerUid || '1');

  const { data: user } = useFirestoreDocData(userRef);

  const isFreeUser =
    user?.stripe?.priceId === 'price_1PTRURERYpQbaFQQuPcJwYiO' ||
    !user?.stripe?.priceId;

  const userLeadsCount = user?.leads?.count || 0;

  const userReachLeadLimit = userLeadsCount >= 2;

  const isHidden = isFreeUser && userReachLeadLimit;

  useEffect(() => {
    if (editMode && !widgetSettings.assistant?.assistantId) {
      return;
    }

    if (data && dataStatus === 'success') {
      setChatId(data?.chatId || data?.NO_ID_FIELD);
      if (data?.messages?.length) {
        const newMessages = [...data.messages].sort(
          (mes1, mes2) => mes1.createdAt.toDate() - mes2.createdAt.toDate(),
        );
        setMessages((prev) => {
          if (newMessages.length < 2) {
            return [...newMessages, prev[prev?.length - 1]].filter(Boolean);
          }
          return newMessages;
        });
      }
    }
  }, [data, dataStatus]);

  const [incomingMessage, setIncomingMessage] = useState('');

  useEffect(() => {
    if (
      leadInfo?.username &&
      incomingMessage &&
      widgetSettings.widgetId &&
      chatId
    ) {
      setStatus('typing');
      setIncomingMessage('');
      getMessage(
        widgetSettings.widgetId,
        chatId,
        widgetSettings.assistant?.assistantId || '',
        incomingMessage,
        messages || [],
        isPaid,
      ).then(() => {
        setTimeout(() => {
          setStatus('online');
        }, 300);
      });
    }
  }, [incomingMessage, leadInfo]);

  const sendMessage = useCallback(
    async (userMessage: string, messages: ChatDoc['messages']) => {
      try {
        console.log('sendMessage', userMessage, messages, data);
        setErrorMessage('');
        setStatus('online');

        if (editMode && !widgetSettings.assistant) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: 'text',
              role: 'user',
              content: userMessage,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus('typing');

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: 'text',
              role: 'assistant',
              content: mockResponse,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus('online');
          return;
        } else {
          if (!widgetSettings.widgetId || !widgetSettings.ownerUid) {
            return;
          }
          let currentChatId = getStorage(`user-widget-id-${widgetId}`);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: 'text',
              role: 'user',
              content: userMessage,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus('typing');
          if (!currentChatId) {
            const newChatId = await createChatDoc(
              widgetSettings.widgetId!,
              widgetSettings.ownerUid!,
              {
                email: leadInfo?.email || '',
                phone: leadInfo?.phone || '',
                username: leadInfo?.username || '',
              },
              widgetSettings.assistant?.assistantId || '',
              isHidden,
            );
            setChatId(newChatId);
            setStorage(`user-widget-id-${widgetId}`, newChatId);
            setStatus('typing');
            console.log('getMessage last');
            currentChatId = newChatId;

            if (numberOfMessagesForStaticForm <= 1) {
              setIncomingMessage(userMessage);
              setStatus('online');
              return;
            }
          }

          setStatus('typing');

          await getMessage(
            widgetSettings.widgetId,
            currentChatId,
            widgetSettings.assistant?.assistantId || '',
            userMessage,
            messages.slice(1) || [],
            isPaid,
          );

          setTimeout(() => {
            setStatus('online');
          }, 300);

          // if (!currentChatId && !firstMessage) {
          //   setFirstMessage(userMessage);
          //   setIsSubmittedFirstMessage(true);

          //   return;
          // }
          // if (
          //   !currentChatId &&
          //   widgetSettings.widgetId &&
          //   widgetSettings.ownerUid &&
          //   (editMode || (leadInfo && numberOfMessagesForStaticForm === 1))
          // ) {
          //   // Create a reference to the collection
          //   const chatsCollectionRef = collection(
          //     DB,
          //     `widgets/${widgetId}/chats`,
          //   );

          //   // Create the query
          //   const chatQuery = query(
          //     chatsCollectionRef,
          //     where('leadEmail', '==', leadInfo?.email || 'email'),
          //   );

          //   const chatsSnapshot = await getDocs(chatQuery);
          //   const chats = chatsSnapshot.docs
          //     .map((doc) => {
          //       return doc.data() as ChatDoc;
          //     })
          //     .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

          //   const lastChat = chats[0];

          //   if (lastChat) {
          //     console.log('getMessage lastChat');
          //     setStorage(`user-widget-id-${widgetId}`, lastChat.chatId);
          //     setChatId(lastChat.chatId);
          //     setStatus('typing');
          //     await getMessage(
          //       widgetSettings.widgetId,
          //       lastChat.chatId,
          //       widgetSettings.assistant?.assistantId || '',
          //       userMessage,
          //       lastChat?.messages.length ? lastChat?.messages : [],
          //       isPaid,
          //     );
          //     setTimeout(() => {
          //       setStatus('online');
          //     }, 300);

          //     return;
          //   }
          //   createChatDoc(
          //     widgetSettings.widgetId,
          //     widgetSettings.ownerUid,
          //     {
          //       email: leadInfo?.email || '',
          //       phone: leadInfo?.phone || '',
          //       username: leadInfo?.username || '',
          //     },
          //     widgetSettings.assistant?.assistantId || '',
          //     isHidden,
          //   )
          //     .then((chatId) => {
          //       setChatId(chatId);
          //       setStorage(`user-widget-id-${widgetId}`, chatId);

          //       setStatus('typing');
          //       const docRef = doc(DB, `widgets/${widgetId}/chats`, chatId);

          //       if (editMode) {
          //         return getMessage(
          //           widgetSettings.widgetId!,
          //           chatId,
          //           widgetSettings.assistant?.assistantId || '',
          //           firstMessage,
          //           [],
          //           isPaid,
          //         );
          //       }

          //       return setDoc(
          //         docRef,
          //         {
          //           messages: [
          //             {
          //               type: 'text',
          //               role: 'assistant',
          //               content: `${
          //                 widgetSettings.startMessage || 'Hello there!'
          //               }`,
          //               createdAt: Timestamp.now(),
          //             },
          //             {
          //               type: 'text',
          //               role: 'assistant',
          //               content: `${widgetSettings.greetingUser?.replace(
          //                 '{leadName}',
          //                 leadInfo?.username || 'User',
          //               )}`,
          //               createdAt: Timestamp.now(),
          //             },
          //           ],
          //         },
          //         { merge: true },
          //       ).then(() =>
          //         getMessage(
          //           widgetSettings.widgetId!,
          //           chatId,
          //           widgetSettings.assistant?.assistantId || '',
          //           firstMessage,
          //           [
          //             {
          //               type: 'text',
          //               role: 'assistant',
          //               content: `${
          //                 widgetSettings.startMessage || 'Hello there!'
          //               }`,
          //               createdAt: Timestamp.now(),
          //             },
          //             {
          //               type: 'text',
          //               role: 'assistant',
          //               content: `${widgetSettings.greetingUser?.replace(
          //                 '{leadName}',
          //                 leadInfo?.username || 'User',
          //               )}`,
          //               createdAt: Timestamp.now(),
          //             },
          //           ],
          //           isPaid,
          //         ),
          //       );
          //     })
          //     .then(() => {
          //       setTimeout(() => {
          //         setStatus('online');
          //       }, 300);
          //     })
          //     .catch((err: any) => {
          //       setErrorMessage(err?.message);
          //       setStatus('error');
          //     });
          // } else {
          //   if (chatId && widgetSettings.widgetId) {
          //     setStatus('typing');
          //     console.log('getMessage chatId && widgetSettings.widgetId');

          //     console.log('messages', messages, data);

          //     await getMessage(
          //       widgetSettings.widgetId,
          //       chatId,
          //       widgetSettings.assistant?.assistantId || '',
          //       userMessage,
          //       messages || [],
          //       isPaid,
          //     );
          //     setTimeout(() => {
          //       setStatus('online');
          //     }, 300);
          //   } else if (editMode || leadInfo) {
          //     const chatsCollectionRef = collection(
          //       DB,
          //       `widgets/${widgetId}/chats`,
          //     );

          //     const chatQuery = query(
          //       chatsCollectionRef,
          //       where('leadEmail', '==', leadInfo?.email || 'email'),
          //     );

          //     const chatsSnapshot = await getDocs(chatQuery);
          //     const chats = chatsSnapshot.docs
          //       .map((doc) => {
          //         return doc.data() as ChatDoc;
          //       })
          //       .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

          //     const lastChat = chats[0];

          //     if (lastChat) {
          //       setChatId(lastChat.chatId);
          //       setStorage(`user-widget-id-${widgetId}`, lastChat.chatId);
          //       setStatus('typing');
          //       console.log('getMessage lastChat 2');

          //       await getMessage(
          //         widgetId!,
          //         lastChat.chatId,
          //         widgetSettings.assistant?.assistantId || '',
          //         userMessage,
          //         lastChat?.messages.length ? lastChat?.messages : [],
          //         isPaid,
          //       );
          //       setTimeout(() => {
          //         setStatus('online');
          //       }, 300);
          //       return;
          //     }
          //     const newChatId = await createChatDoc(
          //       widgetSettings.widgetId!,
          //       widgetSettings.ownerUid!,
          //       {
          //         email: leadInfo?.email || '',
          //         phone: leadInfo?.phone || '',
          //         username: leadInfo?.username || '',
          //       },
          //       widgetSettings.assistant?.assistantId || '',
          //       isHidden,
          //     );
          //     setChatId(newChatId);
          //     setStorage(`user-widget-id-${widgetId}`, newChatId);
          //     setStatus('typing');
          //     console.log('getMessage last');

          //     await getMessage(
          //       widgetSettings.widgetId!,
          //       newChatId,
          //       widgetSettings.assistant?.assistantId || '',
          //       firstMessage,
          //       messages || [],
          //       isPaid,
          //     );
          //     setStatus('online');
          //   }
          // }
        }

        setMessageWasSent(true);
      } catch (error: any) {
        setErrorMessage(error?.message);
        setStatus('error');
        console.error('Error sending message:', error);
      }
    },
    [messages, status, leadInfo, data, chatId, voiceIsReady],
  );

  const applyLeadInfo = useCallback(async (leadInfo: LeadData) => {
    try {
      const chatId = getStorage(`user-widget-id-${widgetId}`);
      setStorage(`completed-form-user-widget-id-${widgetId}-${chatId}`, 'true');
      setLeadInfo(leadInfo);
      const chatDocRef = doc(DB, `widgets/${widgetId}/chats`, chatId);

      await setDoc(
        chatDocRef,
        {
          lead: {
            info: leadInfo,
          },
          leadEmail: leadInfo.email,
        },
        { merge: true },
      );

      const chatDoc = await getDoc(chatDocRef);

      if (chatDoc.exists()) {
        const chatData = chatDoc.data() as ChatDoc;
        const leadId = chatData.leadId;

        if (leadId) {
          const leadDocRef = doc(DB, 'leads', leadId);
          const leadDoc = await getDoc(leadDocRef);

          if (leadDoc.exists()) {
            await setDoc(
              chatDocRef,
              {
                info: {
                  ...leadInfo,
                },
              },
              { merge: true },
            );
          }
        }
      }
    } catch (err: any) {
      console.error('applyLeadInfo', err);
    }
  }, []);

  const clearConversation = useCallback(async () => {
    const id = widgetId || widgetSettings.widgetId;
    const currChatId = getStorage(`user-widget-id-${id}`);
    try {
      if (id && currChatId) {
        await clearAllConversation(id, currChatId);
        setStatus('online');
        setChatId('');
        setLeadInfo(undefined);
        setMessages([]);
      }
    } catch (err: any) {
      console.error(err);
      setStatus('error');
      setErrorMessage(err?.message);
    }
  }, [widgetSettings, widgetId, chatId]);

  const context = useMemo(
    () => ({
      errorMessage,
      clearConversation,
      messages,
      sendMessage,
      status,
      setStatus,
      chatOpen,
      setChatOpen,
      editMode,
      isHome,
      chatId,
      applyLeadInfo,
      leadInfo,
      isAcceptedTerms,
      isIgnoreTerms,
      voiceIsReady,
      setVoiceIsReady,
      messageWasSent,
      waitingForNewResponse,
      setWaitingForNewResponse,
    }),
    [
      chatOpen,
      clearConversation,
      messages,
      sendMessage,
      status,
      editMode,
      isHome,
      errorMessage,
      chatId,
      leadInfo,
      isAcceptedTerms,
      isIgnoreTerms,
      applyLeadInfo,
      voiceIsReady,
      messageWasSent,
      waitingForNewResponse,
    ],
  );

  return (
    <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
  );
};
